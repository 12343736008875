import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Process() {

  const {t} = useTranslation('services');

  return (
    <section className="tc-process-style2">
      <div className="container">
       
        <div className="content">
          <div className="row">
            <div className="col-lg-5">
              <div className="accordion-side wow fadeInUp slow">
                <div className="accordion" id="accordionProcess">
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        <span className="num"> 1 - </span> <h3> {t('focus')}
                        </h3>
                      </button>
                    </div>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                        {t('focus-text')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        <span className="num"> 2 - </span> <h3> {t('mission')}  </h3>
                      </button>
                    </div>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                        {t('mission-text')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                      >
                        <span className="num"> 3 - </span> <h3> {t('values')} </h3>
                      </button>
                    </div>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                        {t('values-text')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                      >
                        <span className="num"> 4 - </span> <h3> {t('long-term')} </h3>
                      </button>
                    </div>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionProcess"
                    >
                      <div className="accordion-body">
                        <div className="text">
                        {t('long-term-text')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Link
                  to={"/projects"}
                >
                    <button type='button' className='btn btn-dark p-3 mt-5'> {t('see-projects')}</button>
                </Link>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="imgs">
                <div className="img" data-lag="0.2">
                  <img
                    src="/home1/assets/img/about-page/1.jpg"
                    alt=""
                    className="img-cover"
                  />
                  
                </div>
                
                <div className="img" data-lag="0.4">
                  <img
                    src="/home1/assets/img/about-page/2.jpg"
                    alt=""
                    className="img-cover"
                  />

                </div>
                <div className="img" data-lag="0.3">
                  <img
                    src="/home1/assets/img/about-page/3.jpg"
                    alt=""
                    className="img-cover"
                  />

                </div>

                <div className="img" data-lag="0.5">
                  <img
                    src="/home1/assets/img/about-page/4.jpg"
                    alt=""
                    className="img-cover"
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/innerpages/assets/img/prc_bg.png"
        alt="mimaria bg"
        className="bg"
        data-speed="1.2"
      />
    </section>
  );
}

export default Process;
