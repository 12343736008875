import React from 'react';
import { useParams } from 'react-router-dom';
import projectsData from '../../../data/innerpages/portfolio/latestcases.json'; 
import NotFound from '../../../pages/innerpages/not-found';



function Project() {

  const { id } = useParams(); // Get the project ID from the URL
  const project = projectsData.find((proj) => proj.id === id); // Find the project by ID

  if (!project) {
    return  <NotFound/>;
  }


  return (
    <section className="tc-project-style1 header">
      <div className="main-info">
        <div className="container">
          <div className="tags mb-50">
            <a > {project.subTitle} </a>

          </div>
          <h1 className="title">{project.title}</h1>
          
        </div>
      </div>
      
      <div className="description">

      <div className="imgs ">
          <div className="container">

            <div className="row">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src={project.img}
                    alt="mimaria architecture"
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src={project['img-2']}
                    
                    alt="mimaria architecture"
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
            
            {/* row-2 */}

            <div className="row mt-5">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src={project['img-3']}
                    alt="mimaria architecture"
                    className="img-cover"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src={project['img-4']}
                    alt="mimaria architecture"
                    className="img-cover"
                  />
                </div>
              </div>
            </div>

            {/* row-3 */}

            <div className="row mt-5">
              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.2s"
                >
                  <img
                    src={project['img-5']}
                    alt="mimaria architecture"
                    className="img-cover"
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div
                  className="img th-600 radius-7 overflow-hidden mt-30 wow zoomIn slow"
                  data-wow-delay="0.4s"
                >
                  <img
                    src={project['img-6']}
                    alt="mimaria architecture"
                    className="img-cover"
                  />
                </div>
              </div>
            </div>



          </div>
        </div>


   

        
       
      </div>
      
      
    </section>
  );
}

export default Project;
