import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t, i18n } = useTranslation('navbar'); // Specify the 'navbar' namespace

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark tc-navbar-style1 section-padding-x">
      <div className="container-fluid content">
        <Link className="navbar-brand" to="/">
          <img src="/assets/logo.png" alt="Logo" className="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" style={{ backgroundColor: "#999" }}></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/" className="nav-link active" aria-current="page" >
                {t('home')}
              </Link>
            </li>
            <li className="nav-item">
              <Link  className="nav-link "  to="/services">
                {t('services')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="projects">
                {t('projects')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="about">
                {t('about')}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="contact">
                {t('contact')}
              </Link>
            </li>
          </ul>
          <div className="nav-side">
            <button type="button" className="btn" onClick={() => changeLanguage('en')}>
              <span>EN 🇬🇧</span>
            </button>
            

            <button type="button" className="btn" onClick={() => changeLanguage('tr')}>
              <span>TR 🇹🇷</span>
            </button>

            <Link className="icon ms-3 side_menu_btn fsz-21">
              <span></span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
