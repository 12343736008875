import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Terms = () => {

    const {t} = useTranslation('home');

  return (
    <div className="container text-center" style={{ padding: '100px 0' }}>
      <h1 className="display-4 mt-5 mb-3">{t('terms')}</h1>
      <p className="lead mb-5 mt-5">{t('term-1')}</p>
      <p className="lead mb-5 mt-5">{t('term-2')}</p>
      <p className="lead mb-5 mt-5">{t('term-3')}</p>
      <p className="lead mb-5 mt-5">{t('term-4')}</p>
      <p className="lead mb-5 mt-5">{t('term-5')}</p>
      <p className="lead mb-5 mt-5">{t('term-6')}</p>
      <p className="lead mb-5 mt-5">{t('term-7')}</p>
      <p className="lead mb-5 mt-5">{t('term-8')}</p>
      <p className="lead mb-5 mt-5">{t('term-9')}</p>
      

      <Link to="/" className="btn btn-outline-primary">
        Go Back Home
      </Link>
    </div>
  );
};

export default Terms;
