import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Eagerly load translation files
import enNavbar from './locales/en/navbar.json';
import trNavbar from './locales/tr/navbar.json';
import enHome from './locales/en/home.json';
import trHome from './locales/tr/home.json';
import enServices  from './locales/en/services.json'
import trServices  from './locales/tr/services.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // List of supported languages
    supportedLngs: ['en', 'tr'],
    
    // Default fallback language
    fallbackLng: 'tr',
    
    // Preload all supported languages to avoid lazy loading
    preload: ['en', 'tr'],

    detection: {
      // Define language detection order
      order: ['cookie', 'localStorage', 'navigator', 'htmlTag'],
      // Temporarily disable caching to avoid cache issues
      caches: [], 
    },

    // Eagerly load all namespaces and their resources
    resources: {
      en: {
        navbar: enNavbar,
        home: enHome,
        services: enServices,
        
      },
      tr: {
        navbar: trNavbar,
        home: trHome,
        services: trServices,
      },
    },

    // Specify namespaces if you have multiple
    ns: ['navbar', 'home','services'], // List of namespaces
    defaultNS: 'navbar',     // Default namespace

    // React-specific options
    react: {
      useSuspense: false, // Disable suspense to avoid any lazy loading issues
    },

    // Enable debug mode for console logs to track missing keys or other issues
    debug: true,  // Set this to false in production
  });

export default i18n;
