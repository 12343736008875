import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Services() {

  const { t } = useTranslation('home'); 

  return (
    <section className="tc-services-style1">
      <div className="content section-padding section-padding-x">
        <div className="container">
          <div className="title mb-80 text-center">
            <p className="color-666 text-uppercase wow"> {t('our-services')} </p>
          </div>
          <div className="services">
            <div className="row">
              <div className="col-lg-3">
                <Link
                  to="/services"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="icon">
                    <i className="la la-hard-hat"></i>
                  </div>
                  <h5 className="fsz-24 mb-20"> {t('architectural-design')} </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/home-page/services/ser1.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  {t('architectural-design-text')}
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </Link>
              </div>
              <div className="col-lg-3">
                <Link
                  to="/services"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="icon">
                    <i className="la la-bezier-curve"></i>
                  </div>
                  <h5 className="fsz-24 mb-20">  {t('interior-architecture-design')} </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/home-page/services/ser2.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  {t('interior-architecture-design-text')}
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </Link>
              </div>
              <div className="col-lg-3">
                <Link
                  to="/services"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="icon">
                    <i className="ti-reload"></i>
                  </div>
                  <h5 className="fsz-24 mb-20"> {t('restoration-renovation')} </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/home-page/services/ser3.webp"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  {t('restoration-renovation-text')}
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </Link>
              </div>
              <div className="col-lg-3">
                <Link
                  to="/services"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                  >
                  <div className="icon">
                    <i className="la la-comments"></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                  {t('project-management')}
                  </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/home-page/services/ser4.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                  {t('project-management-text')}
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="text-center mb-5">
            <Link
              to="/contact"
              className="mt-80"
            >
              
              <button type='button' className='btn btn-dark p-3'> {t('free-quote')}</button>
            
            </Link>
          </div>
        </div>
      </div>
      
    </section>
  );
}

export default Services;
