import React from 'react';

import Navbar from '../components/home1/Navbar';

import Experience from '../components/home1/Experience';
import Services from '../components/home1/Services';


import Testimonials from '../components/home1/Testimonials';
import Footer from '../components/home1/Footer';
import { Helmet } from 'react-helmet';
import Cases from '../components/home10/Cases';
import Header from '../components/home1/Header';


function Home() {

  

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/home1/assets/css/home_1_style.css"
        />
        <script src="/common/assets/js/common_js.js"></script>
        <script src="/home1/assets/js/home_1_scripts.js"></script>
      </Helmet>
      <body className="home-style1">

        <div className="smooth-scroll-content" id="scrollsmoother-container">
          <Navbar />
          <Header />
          <main>
            <Services />
            <Experience />
            <Cases />
            <Testimonials />
          </main>
          <Footer />
        </div> 
      </body>
    </>
  );
}

export default Home;
