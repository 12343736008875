import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

function Navbar2() {
  const { t, i18n } = useTranslation('navbar'); // Specify the 'navbar' namespace
  const location = useLocation(); // Get the current location

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // Function to check if the current route matches the link's path
  const isActive = (path) => location.pathname === path;

  return (
    <nav className="navbar navbar-expand-lg navbar-light inner-navbar-style1">
      <div className="container-fluid content">
        <Link className="navbar-brand" to="/">
          <img src="/assets/logo.png" alt="Logo" className="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/" className={`nav-link ${isActive('/') ? 'active' : ''}`}>
                {t('home')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/services" className={`nav-link ${isActive('/services') ? 'active' : ''}`}>
                {t('services')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/projects" className={`nav-link ${isActive('/projects') ? 'active' : ''}`}>
                {t('projects')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className={`nav-link ${isActive('/about') ? 'active' : ''}`}>
                {t('about')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className={`nav-link ${isActive('/contact') ? 'active' : ''}`}>
                {t('contact')}
              </Link>
            </li>
          </ul>
          <div className="nav-side">
            <button type="button" className="btn" onClick={() => changeLanguage('en')}>
              <span>EN 🇬🇧</span>
            </button>

            <button type="button" className="btn" onClick={() => changeLanguage('tr')}>
              <span>TR 🇹🇷</span>
            </button>

            <Link className="icon ms-3 side_menu_btn fsz-21">
              <span></span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar2;
