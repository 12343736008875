import React from 'react';
import { Link } from 'react-router-dom';
import data from '../../data/home1/slider';
import { useTranslation } from 'react-i18next';


function Header() {

  const { t } = useTranslation('home'); // Specify the 'navbar' namespace

  



  return (

    <header className="tc-header-style1">
      <div className="header-slider">
        <div className="swiper-wrapper">
          {data.map((item, i) => (
            <div key={i} className="swiper-slide">
              <div className="slider-card">
                <div className="img">
                  <img src={item.img} alt="" className="img-cover" />
                </div>
                <div className="info section-padding-x">
                  <div className="row align-items-end">
                    <div className="col-lg-6">
                      
                    </div>
                    <div className="col-lg-3">
                      <div className="cont pb-30">
                        <h6 className="fsz-20 text-uppercase mb-30 fw-300">
                          {item.history}
                        </h6>
                        <div className="text fsz-16 fw-300 lh-4">
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="float-box" data-swiper-parallax="500">

                  <h4>{t('header-title')}</h4>
                  <ul className="fsz-20 color-666 mt-30">
                    <li>
                    <h6>{t('header-sub-title')}</h6>
                    </li>
                    
                  </ul>
                  {/* Add the "See More" button here */}
                  <Link to="/projects" className="btn btn-dark mt-4">
                  {t('see-more')}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="slider-controls">
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
        </div>
      </div>
    </header>
  );
}

export default Header;
