import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


function Footer() {

  const currentYear = new Date().getFullYear();
  const { t } = useTranslation('navbar'); // Specify the 'navbar' namespace

  return (
    <footer className="tc-footer-style1">
      <div className="container">
        <div className="top-content section-padding-footer">
          <div className="row gx-0">
            <div className="col-lg-4">
              <div className="info-side">
                <div className="text fsz-24 color-333 lh-3 fw-600">
                  {t('footer-text')}
                </div>
                <div className="foot-social mt-50">
  <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/mimaria_mimarlik?igsh=cm1paDcyN3BkMHp1'>
    <i className="fab fa-instagram"></i>
  </a>
  <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/in/mukaddes-agc%C4%B1cek-98a74480?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'>
    <i className="fab fa-linkedin-in"></i>
  </a>
</div>

              </div>
            </div>
            <div className="col-lg-3 offset-lg-2">
              
              <div className="branch-card">
                <h5 className="mb-20 mt-5 fw-600"> {t('footer-projects')} </h5>
                <ul className="footer-links">
                  <li>
                    <Link to='/projects/1'> Pendik</Link>
                  </li>
                  <li>
                  <Link to='/projects/10'> Beyoğlu</Link>
                  </li>
                  <li>
                  <Link to='/projects/8'> Mavi CLub</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
            
              <div className="branch-card">
                <h5 className="mb-20 mt-5 fw-600"> {t('info')} </h5>
                <ul className="footer-links">
                  <li>
                    <Link to='/privacy-policy'> {t('privacy')}  </Link>
                  </li>

                  <li>
                  <Link target='blank' to='https://maps.app.goo.gl/iNf4fjbHntKXcgvAA'> Varyap Meridian Grand Tower  </Link>
                  </li>

                  <li>
                  <Link to={"tel:+905055825900"}> +90-505-582-59-00 </Link>
                  </li>

                  <li>
                  <Link to={"mailto:mukaddesagcicek@gmail.com"}> mukaddesagcicek@gmail.com </Link>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="row">
            <div className="col-lg-6">
              <p className="fsz-13">© {currentYear} Mimaria Architecture. All Right Reserved</p>
              <Link to="https://creamycode.com" target='blank'  style={{
    color: '#0056b3', 
    textDecoration: 'none', 
    marginTop:"5px",

  }}
  onMouseEnter={e => e.target.style.color = '#007bff'} 
  onMouseLeave={e => e.target.style.color = '#0056b3'}>
  Designed by creamycode.com</Link>
            </div>
            <div className="col-lg-6">
              <div className="foot-links mt-4 mt-lg-0">
                <Link to='/'> {t('home')} </Link>
                <Link to='/services'> {t('services')} </Link>
                <Link to='/projects'> {t('projects')} </Link>
                <Link to='/about'> {t('about')} </Link>
                <Link to='/contact'> {t('contact')} </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
