import React from 'react';

function Map() {
  return (
    <section className="tc-map-style1">
      <div className="map-card wow zoomIn slow" data-wow-delay="0.2s">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.4336927966833!2d29.10798037595709!3d40.993879820311456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac620c83673c3%3A0x57a026636fc8b443!2sVaryap%20Meridian%20Grand%20Tower!5e0!3m2!1sen!2spl!4v1726653580933!5m2!1sen!2spl"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>


      </div>
    </section>
  );
}

export default Map;
