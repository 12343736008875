import React from 'react';
import { useTranslation } from 'react-i18next';

function Header() {
  
const {t} = useTranslation('services');

  return (
    <header className="tc-inner-header-style1 pb-100">
      <div className="container">
        <div className="info">
          <h1 className="js-title"> {t('contact-title')} </h1>
          <div className="text fsz-18 color-666">
            {t('contact-p1')}{' '}
            <br /> {t('contact-p2')}{' '}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
