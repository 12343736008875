'use client';
import React, { useEffect, useRef, useState } from 'react';
import data from '../../../data/innerpages/portfolio/latestcases';
import mixitup from 'mixitup';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
function LatestCases() {

  const [activeFilter, setActiveFilter] = useState('All');
  const mixitupContainerRef = useRef(null);

  const {t} = useTranslation('services');


  useEffect(() => {
    const initializeMixitup = () => {
      mixitup(mixitupContainerRef.current, {
        load: {
          sort: 'order:asc',
        },
        animation: {
          duration: 700,
        },
        classNames: {
          block: 'filter',
          elementFilter: 'filter-btn',
          elementSort: 'sort-btn',
        },
        selectors: {
          target: '.mix-item',
        },
      });
    };

    initializeMixitup();
  }, []);
  const handleFilterClick = (filter) => {
    setActiveFilter(filter);


    document.querySelectorAll('.filter-btn').forEach((btn) => {
      btn.classList.remove('active');
    });


    const clickedButton = document.querySelector(`[data-filter="${filter}"]`);
    if (clickedButton) {
      clickedButton.classList.add('active');
    }
  };

  return (
    <section className="tc-latest-cases-style1">
      <div className="container">
        <div className="content">
          <h3 className="fsz-45 text-capitalize mb-90 js-splittext-lines">
            {t('recent-works')}
          </h3>
          <div className="cases">
            <div
              className="filter mb-60 wow fadeInUp slow"
              data-wow-delay="0.2s"
            >
              <div className="links">
                <a
                  onClick={() => handleFilterClick('All')}
                  className={`filter-btn ${
                    activeFilter === 'All' ? 'active' : ''
                  }`}
                  href="#0"
                  data-filter="*"
                >
                  {t('all')}
                </a>
                <a
                  href="#0"
                  onClick={() => handleFilterClick('Architecture')}
                  className={`filter-btn ${
                    activeFilter === 'Architecture' ? 'active' : ''
                  }`}
                  data-filter=".Architecture"
                >
                  {t('architecture')}
                </a>
                <a
                  href="#0"
                  onClick={() => handleFilterClick('Interior')}
                  className={`filter-btn ${
                    activeFilter === 'Interior' ? 'active' : ''
                  }`}
                  data-filter=".Interior"
                >
                  {t('interior')}
                </a>

                <a
                  href="#0"
                  onClick={() => handleFilterClick('Historical')}
                  className={`filter-btn ${
                    activeFilter === 'Renovation' ? 'active' : ''
                  }`}
                  data-filter=".Historical"
                >
                  {t('restoration')}
                </a>
                
              </div>
            </div>
            <div
              className="cases-content wow fadeInUp slow"
              data-wow-delay="0.4s"
            >
              <div className="row " ref={mixitupContainerRef}>
                {data.map((item, i) => (
                  <div key={i} className={`col-lg-4 mix-item ${item.subTitle}`}>
                    <div className="case-card">
                      <a href={`/projects/${item.id}`} className="img" >
                        <img src={item.img} alt={item.title} className="img-cover" />
                      </a>
                      <div className="info">
                        <div className="tags mb-30">
                          <a href="#"> {item.subTitle.split('<br />')[0]} </a>
                          {item.subTitle.split('<br />')[1] && (
                            <a href="#"> {item.subTitle.split('<br />')[1]} </a>
                          )}
                        </div>
                        <h3 className="title fsz-35 mb-20">
                          <a href="#" className="hover-orange1">
                            {item.title}
                          </a>
                        </h3>
                        <div className="text color-666">
                          {item.desc.split('<br />')[0]}
                          <br /> {item.desc.split('<br />')[1]}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>

          <div className='text-center'>
          <Link to={"/contact"}>
          <button className='btn btn-dark p-4' type='button' >{t('contact-title')}</button>
          </Link>
             </div>  
        </div>

        
      </div>
                   
    </section>
  );
}

export default LatestCases;
