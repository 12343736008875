import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";



function Cases() {

  const { t } = useTranslation('home'); 

  return (
    <section className="tc-cases-style10 section-padding-x section-padding">
      <div className="container-fluid">
        <div className="title mb-50">
          <div className="row align-items-end">
            <div className="col-lg-6">
              <div className="section-head-style10 js-splittext-lines">
                <div className="sub-title mb-30"> {t('our-projects')} </div>
                <h2> {t('featured-projects')} </h2>
              </div>
            </div>
            <div className="col-lg-6 text-lg-end mt-5 mt-lg-0">
             
            </div>
          </div>
        </div>
        <div className="tab-content" id="v-pills-tabContent">
          <div className="tab-pane fade show active" id="v-pills-tab1">
            <div className="cases-content">
              <div className="row">

                <div className="col-lg-6">
                  <Link
                    to="/projects"
                    className="case-item no_scale"
                  >
                    <div className="img">
                      <img
                        src="/home1/assets/img/home-page/featured/1.webp"
                        alt="mimaria-img"
                        className="img-cover"
                      />
                    </div>
                    <div className="info">
                      <h5 className="fsz-40 mb-2"> Ataşehir</h5>
                      <small className="fsz-12"> Architecture </small>
                    </div>
                    <span className="arrow">
                      <i className="ti-arrow-top-right"></i>
                    </span>
                  </Link>
                </div>
                <div className="col-lg-6">
                  <Link
                    to="/projects"
                    className="case-item no_scale"
                  >
                    <div className="img">
                      <img
                        src="/home1/assets/img/home-page/featured/2.webp"
                        alt="mimaria-img"
                        className="img-cover"
                      />
                    </div>
                    <div className="info">
                      <h5 className="fsz-40 mb-2">Kadıköy Fenerbahçe</h5>
                      <small className="fsz-12"> Architecture </small>
                    </div>
                    <span className="arrow">
                      <i className="ti-arrow-top-right"></i>
                    </span>
                  </Link>
                </div>
                
                <div className="col-lg-6">
                  <Link
                    to="/projects"
                    className="case-item no_scale"
                  >
                    <div className="img">
                      <img
                        src="/home1/assets/img/home-page/featured/3.webp"
                        alt="mimaria-img"
                        className="img-cover"
                      />
                    </div>
                    <div className="info">
                      <h5 className="fsz-40 mb-2">
                      Kartal
                      </h5>
                      <small className="fsz-12"> Architecture</small>
                    </div>
                    <span className="arrow">
                      <i className="ti-arrow-top-right"></i>
                    </span>
                  </Link>
                </div>
                <div className="col-lg-6">
                  <Link
                    to="/projects"
                    className="case-item no_scale"
                  >
                    <div className="img">
                      <img
                        src="/home1/assets/img/home-page/featured/4.webp"
                        alt="mimaria-img"
                        className="img-cover"
                      />
                    </div>
                    <div className="info">
                      <h5 className="fsz-40 mb-2"> Pendik </h5>
                      <small className="fsz-12">
                        Architectural Design
                      </small>
                    </div>
                    <span className="arrow">
                      <i className="ti-arrow-top-right"></i>
                    </span>
                  </Link>
                </div>
                

              </div>
            </div>
          </div>
          
        </div>
        <div className="text-center">


        <Link
              to="/projects"
              className="mt-80"
            >
              
              <button type='button' className='btn btn-dark p-3'> {t('see-all-projects')}</button>
              </Link>
         
        </div>
      </div>
    </section>
  );
}

export default Cases;
