import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import BlogPage from './pages/innerpages/blog';
// import SinglePost from './pages/innerpages/single_post';

import AboutPage from './pages/innerpages/about';
import ContactPage from './pages/innerpages/contact';
import PortfolioPage from './pages/innerpages/portfolio';
import ServicesPage from './pages/innerpages/services';
// import SingleProject from './pages/innerpages/single_project';

import Home from './pages';
import NotFound from './pages/innerpages/not-found';
import SingleProject from './pages/innerpages/single_project';
import Terms from './pages/innerpages/terms';
import Privacy from './pages/innerpages/privacy-policy';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home   />} />
        <Route path="/home" element={<Home   />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/projects" element={<PortfolioPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/projects/:id" element={<SingleProject />} />

        <Route path="/services" element={<ServicesPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />

        <Route path="*" element={<NotFound />} />

       
        {/* <Route path="/blog" element={<BlogPage />} />
        <Route path="/single-post" element={<SinglePost />} /> */}

      </Routes>
      
    </Router>
  );
}

export default App;
