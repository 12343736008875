import React from 'react';

import Footer from '../../components/innerpage/Footer';
import Navbar2 from '../../components/innerpage/Navbar2';
import Header from '../../components/innerpage/contact/Header';

import Map from '../../components/innerpage/contact/Map';
import ContactForm from '../../components/innerpage/contact/ContactForm';
import { Helmet } from 'react-helmet';

function ContactPage() {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/innerpages/assets/css/innerpages.css"
        />
        <script src="/common/assets/js/common_js.js"></script>
        <script src="/innerpages/assets/js/innerpages.js"></script>
      </Helmet>
      <body className="inner-pages-style1 contact-pg-style1">
        {/* <Loader /> */}
        
        <div className="smooth-scroll-content" id="scrollsmoother-container">
          <Navbar2 />
          <Header />

          <main>

            <Map />
            <ContactForm />
          <div className='mt-5'></div>
          </main>
          <Footer />
        </div>

      </body>
    </>
  );
}

export default ContactPage;
