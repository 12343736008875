import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Testimonials() {
  
  const {t} = useTranslation('home');

  return (
    <section className="tc-testimonials-style1">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <h6 className="fsz-18 text-uppercase lh-4">
              {t('what-client-say')} <br /> {t('about-us')}
            </h6>
            <div className="lg-icon color-orange1">
              <i className="la la-quote-right"></i>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="tc-clients-style1">
              <div className="clients-slider1">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="clients-card">
                      <div className="text fsz-45 fw-600 lh-2 js-splittext-lines">
                      "Proje süresince işlevselliği ve estetiği bir araya getirerek tam anlamıyla ihtiyaçlarımıza uygun bir tasarım ortaya koydular."
                      </div>
                      <div className="author">
                        <div className="au-img">
                          <img
                            src="/home1/assets/img/team/user.jpg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="au-inf">
                          <h6 className="text-capitalize mb-2 fsz-16 fw-bold">
                          Ali K.
                          </h6>
                          <p className="text-capitalize fsz-14 color-666">
                          {t('happy-client')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="clients-card">
                      <div className="text fsz-45 fw-600 lh-2 js-splittext-lines">
                      "Mimaria, iç mekan tasarımımızda mimari detaylara gösterdiği özenle bizi etkiledi. Alanın her köşesini işlevsel ve estetik bir şekilde kullanarak yenilikçi çözümler sundular."
                      </div>
                      <div className="author">
                        <div className="au-img">
                          <img
                            src="/home1/assets/img/team/user.jpg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="au-inf">
                          <h6 className="text-capitalize mb-2 fsz-16 fw-bold">
                          Emir S.
                          </h6>
                          <p className="text-capitalize fsz-14 color-666">
                          {t('happy-client')} 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="clients-card">
                      <div className="text fsz-45 fw-600 lh-2 js-splittext-lines">
                      "Yeni konut projemiz için Mimaria ile çalıştık ve gerçekten güzel bir sonuç aldık. Projede modern çizgiler ve sürdürülebilir malzemeler kullanarak hem estetik hem de çevre dostu bir tasarım sundular."
                      </div>
                      <div className="author">
                        <div className="au-img">
                          <img
                            src="/home1/assets/img/team/user.jpg"
                            alt=""
                            className="img-cover"
                          />
                        </div>
                        <div className="au-inf">
                          <h6 className="text-capitalize mb-2 fsz-16 fw-bold">
                          Murat C.
                          </h6>
                          <p className="text-capitalize fsz-14 color-666">
                          {t('happy-client')} 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slider-controls">
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-button-next"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="marq-slider">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <Link to={'/#'}> {t('architecture')} </Link>
          </div>
          <div className="swiper-slide">
            <Link to={'/#'}> {t('interior-design')} </Link>
          </div>
          <div className="swiper-slide">
            <Link to={'/#'}> {t('renovation')} </Link>
          </div>
        </div>
      </div>
      
    </section>
  );
}

export default Testimonials;
