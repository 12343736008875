import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="container text-center" style={{ padding: '100px 0' }}>
      <h1 className="display-4 mt-5 mb-3">404 - Page Not Found</h1>
      <p className="lead mb-5">Oops! The page you're looking for doesn't exist.</p>
      <Link to="/" className="btn btn-outline-primary">
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFound;
