import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Experience() {

  const currentYear = new Date().getFullYear();
  const {t} = useTranslation('home');

  return (
    <section className="tc-experience-style1 section-padding-x">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-2">
            <div className="exp-num mb-100 wow zoomIn" data-wow-delay="0.3s">
              <h5 className="fsz-18 text-uppercase">
                {t('years-of')} <br /> {t('experience')}
              </h5>
              <h2 className="num"> {currentYear - 2010} </h2>
            </div>
          </div>
          
          <div className="col-lg-3">
            <div className="img wow">
              <img
                src="/home1/assets/img/home-page/about-section-home.webp"
                alt=""
                // className="img-cover"
              />
            </div>
          </div>

          <div className="col-lg-3">
            <div className="info wow fadeInUp" data-wow-delay="0.3s">
              <h3 className="fsz-45 fw-600 mb-30"> {t('2010')} </h3>
              <div className="text fsz-15 color-666">
              {t('about-text')}
              </div>
              <Link
                to={'/about'}
                className="butn rounded-pill mt-50 hover-bg-black bg-white"
              >
                <span>
                  {t('see-more')} <i className="small ms-1 ti-arrow-top-right"></i>
                </span>
              </Link>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="rotate-txt justify-content-lg-end">
              <ul>
                <li>
                  <Link to={"mailto:mukaddesagcicek@gmail.com"}> mukaddesagcicek@gmail.com </Link>
                </li>
                <li>
                  <Link to={"tel:+905055825900"}> +90-505-582-59-00 </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  );
}

export default Experience;
