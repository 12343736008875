import React from 'react';

import Footer from '../../components/innerpage/Footer';

import Navbar2 from '../../components/innerpage/Navbar2';

import Project from '../../components/innerpage/single_project/Project';


import { Helmet } from 'react-helmet';

function SingleProject() {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/innerpages/assets/css/innerpages.css"
        />
        <script src="/common/assets/js/common_js.js"></script>
        <script src="/innerpages/assets/js/innerpages.js"></script>
      </Helmet>
      <body className="inner-pages-style1 s-project-pg-style1">

        <div className="smooth-scroll-content" id="scrollsmoother-container">
          <Navbar2 />
          <main>
            <Project />
            <div className='mb-5'></div>
          </main>
          <Footer />
        </div>

      </body>
    </>
  );
}

export default SingleProject;
