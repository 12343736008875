import React from 'react';

import About from '../../components/innerpage/about/About';
import Process  from '../../components/innerpage/about/Process';
import { Helmet } from 'react-helmet';
import Navbar2 from '../../components/innerpage/Navbar2';
import Footer from '../../components/innerpage/Footer';
import Header from '../../components/innerpage/about/Header';

function AboutPage() {

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/innerpages/assets/css/innerpages.css"
        />
        <script src="/common/assets/js/common_js.js"></script>
        <script src="/innerpages/assets/js/innerpages.js"></script>
      </Helmet>
      <body className="inner-pages-style1 services-pg-style1">

        <div className="smooth-scroll-content" id="scrollsmoother-container">
          <Navbar2 />
          <Header />
          <main>
            <About />
            <Process />

            <div className='mb-5'>

            </div>
          </main>
          <Footer />
        </div>


      </body>
    </>
  );
}

export default AboutPage;
