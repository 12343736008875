import React from 'react';

import Footer from '../../components/innerpage/Footer';
import Header from '../../components/innerpage/services/Header';

import Process from '../../components/innerpage/services/Process';
import Testimonials from '../../components/innerpage/services/Testimonials';
import { Helmet } from 'react-helmet';
import Navbar2 from '../../components/innerpage/Navbar2';



function ServicesPage() {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/innerpages/assets/css/innerpages.css"
        />
        <script src="/common/assets/js/common_js.js"></script>
        <script src="/innerpages/assets/js/innerpages.js"></script>
      </Helmet>
      <body className="inner-pages-style1 services-pg-style1">


        <div className="smooth-scroll-content" id="scrollsmoother-container">
          <Navbar2 />
          <Header />
          <main>
            <Process />

            <Testimonials />
          </main>
          <Footer />
        </div>
        
      </body>
    </>
  );
}

export default ServicesPage;
